import Navbar from "../components/navbar";

const NotFound = () => {
  return (
    <div>
      <Navbar />
      <div>
        <h1>404</h1>
        <b>Opps...</b>
        <p>The requested page is currently unavailable.</p>
        <p>Please try again later or contact the site admin for support.</p>
      </div>
    </div>
  );
};

export default NotFound;
