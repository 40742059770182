import React, { useEffect, useState } from "react";
import axios from "../../api/axios";

const GuideConversations = () => {
  const messages_URL = "/api/messages?format=json";

  const [activeChats, setActiveChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);

  useEffect(() => {
    axios.get(messages_URL).then((response) => {
      const bookingsWithChats = [
        ...new Set(response.data.map((msg) => msg.booking)),
      ];
      setActiveChats(bookingsWithChats);
    });
  }, []);

  const handleChatSelect = async (bookingId) => {
    // Collapse the currently selected chat if different from the clicked one
    if (selectedChat !== bookingId) {
      setSelectedChat(bookingId);
      const response = await axios.get(`${messages_URL}&booking=${bookingId}`);
      setChatMessages(response.data);
    } else {
      setSelectedChat(null);
      setChatMessages([]);
    }
  };

  return (
    <div>
      <div className="scrollable">
        {activeChats.length > 0 ? (
          activeChats.map((bookingId) => (
            <div
              className={`chat-preview ${
                selectedChat === bookingId ? "expanded" : ""
              }`}
              key={bookingId}
              onClick={() => handleChatSelect(bookingId)}
            >
              <p>
                <b>Chat</b>: {bookingId}
              </p>
              {/* You can add more details here based on your needs */}
            </div>
          ))
        ) : (
          <p>No active chats. Start a new chat.</p>
        )}
      </div>
      {selectedChat && (
        <div className="chat-messages">
          {chatMessages.map((message) => (
            <div className="message" key={message.id}>
              <p>
                {message.sender}: {message.message}
              </p>
              <p>Sent on: {message.sent_date}</p>
            </div>
          ))}
          <div className="message-input">
            <input type="text" placeholder="Type message..." />
            <button>Send</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuideConversations;
