import React, { useState } from "react";
import axios from "../../api/axios";

const AddTour = () => {
  const [tourData, setTourData] = useState({
    name: "",
    description: "",
    price: "",
    city: "",
    duration: "",
    capacity: "",
    status: true, // Default value for status
    // Add fields for image upload
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post("/api/add_tour/", tourData);
      // Display success message or redirect to a different page
    } catch (error) {
      // Display error message
    }
  };

  return (
    <div>
      <h4>Add New Tour</h4>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Tour Name"
          value={tourData.name}
          onChange={(e) => setTourData({ ...tourData, name: e.target.value })}
        />
        <br />
        <textarea
          placeholder="Description"
          value={tourData.description}
          onChange={(e) =>
            setTourData({ ...tourData, description: e.target.value })
          }
        />
        <br />
        <input
          type="number"
          placeholder="Price"
          value={tourData.price}
          onChange={(e) => setTourData({ ...tourData, price: e.target.value })}
        />
        <br />
        <input
          type="text"
          placeholder="City"
          value={tourData.city}
          onChange={(e) => setTourData({ ...tourData, city: e.target.value })}
        />
        <br />
        <input
          type="number"
          placeholder="Duration"
          value={tourData.duration}
          onChange={(e) =>
            setTourData({ ...tourData, duration: e.target.value })
          }
        />
        <br />
        <input
          type="number"
          placeholder="Capacity"
          value={tourData.capacity}
          onChange={(e) =>
            setTourData({ ...tourData, capacity: e.target.value })
          }
        />
        <br />
        <input type="file" id="image" accept=".jpg,.png,.jpeg" />
        <br />
        <input type="file" id="image" accept=".jpg,.png,.jpeg" />
        <br />
        <input type="file" id="image" accept=".jpg,.png,.jpeg" />
        <br />
        <input type="file" id="image" accept=".jpg,.png,.jpeg" />
        <br />
        <input type="file" id="image" accept=".jpg,.png,.jpeg" />
        <br />
        <input type="file" id="image" accept=".jpg,.png,.jpeg" />
        <br />
        <br />
        {/* Add fields for images */}
        <button type="submit" className="button">
          Add Tour
        </button>
      </form>
    </div>
  );
};

export default AddTour;
