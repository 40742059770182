import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FAQ from "./faq";
import logo from "../images/Asset 1.svg";
import Footer from "./footer";

const Home = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "What is Honest Guides and how does it work?",
      answer:
        "Honest Guides is a platform that connects travel agents with local freelance guides around the world. Agents can choose which guides to work with based on their reviews and specialties, and payments are handled through the platform. Guides can choose which agencies to work with and set their own prices, while being held to a high international standard of service. The platform also offers benefits like minimum wage, medical insurance, pay, and UIF to registered guides.",
      open: false,
    },
    {
      question: "How do I become a guide on Honest Guides?",
      answer:
        "To become a guide on Honest Guides, you need to register on the platform and complete the onboarding documents. You will be asked to provide your personal details, professional experience, and tour offerings. Once you are approved as a guide, you can set your own prices and start receiving booking requests from agents on the platform.",
      open: false,
    },
    {
      question: "How do I become an agent on Honest Guides?",
      answer:
        "To become an agent on Honest Guides, you need to register on the platform and complete the onboarding documents. You will be asked to provide your agency details, industry experience, and booking requirements. Once you are approved as an agent, you can search for guides on the platform based on their reviews and specialties, and book them for your clients.",
      open: false,
    },
    {
      question: "What benefits does Honest Guides offer to guides?",
      answer:
        "Honest Guides offers a range of benefits to registered guides, including minimum wage, medical insurance, pay, and UIF. These benefits aim to protect guides from being underpaid or exploited, while also holding them to a high international standard of service. By providing these benefits, Honest Guides hopes to create a global community of guides who are committed to the well-being of all the people involved in making memorable travel possible.",
      open: false,
    },
    {
      question:
        "What happens if a client or agent violates the community guidelines on Honest Guides?",
      answer:
        "If a client or agent violates the community guidelines on Honest Guides, guides can blacklist them on the platform. This means that they will not be able to book that client or agent again in the future. The community guidelines are designed to ensure that travelers are respectful and considerate of local customs, while also protecting the locals and indigenous people of the places they visit. By enforcing these guidelines, Honest Guides hopes to create a healthy and positive travel community.",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  const navigate = useNavigate();
  const navToLogin = () => {
    navigate("/login");
  };
  const navToAbout = () => {
    navigate("/about");
  };

  return (
    <div className="HomePage">
      <header className="home-nav">
        <Link to="/">
          <img src={logo} className="home-logo" alt="logo" />
        </Link>

        <div className="home-nav-btn-div">
          <button className="home-nav-btn" onClick={navToLogin}>
            Login
          </button>
        </div>
      </header>

      <div className="home-hero">
        <div className="home-hero-contents">
          <h2>
            Join a global community committed to the well-being of travelers and
            guides
          </h2>
          <p>
            <b>Start your journey with us...</b>
          </p>
          <button
            onClick={() =>
              window.scrollTo({
                top: document.getElementById("guide-cta").offsetTop,
                behavior: "smooth",
              })
            }
          >
            Guide
          </button>

          <button
            onClick={() =>
              window.scrollTo({
                top: document.getElementById("dmc-cta").offsetTop,
                behavior: "smooth",
              })
            }
          >
            Agent
          </button>
        </div>
      </div>

      <div className="value-prop-div">
        <div className="value-prop-row">
          <div className="value-prop-text">
            <h2>Discover the World with Honest Guides</h2>
            <p>
              We believe in providing authentic travel experiences that respect
              local customs and cultures. Our platform connects travelers with
              trustworthy guides who are committed to providing high-quality
              service and upholding our community guidelines.
            </p>
            <button onClick={navToAbout}>Learn More</button>
          </div>
          <div className="value-prop-image">
            <img
              className="htg-about"
              src={require("../images/michel-stockman-HYEk7So9juc-unsplash.jpg")}
              alt="Honest Travel Guides"
            />
          </div>
        </div>
      </div>

      <div className="how-to-div">
        <div className="how-to-row">
          <div className="how-to-image">
            <img
              className="htg-pin"
              src={require("../images/pin white.png")}
              alt="HTG Pin"
            />
          </div>
          <div className="how-to-text">
            <h3>Join Our Global Community</h3>
            <p>
              • Get connected with reputable agencies and clients from around
              the world
            </p>
            <p>
              • Receive support from a community of fellow guides and agents
            </p>
            <br />
            <button onClick={navToLogin}>Join Now</button>
          </div>
        </div>
      </div>

      <div>
        <div className="get-started">
          <h3>Get Started with Honest Travel Guides</h3>
          <p>
            Fill in the below forms for the role you would like to be registered
            for. <br />
            Submit and we will reach out to complete your onboarding to{" "}
            <b>
              <i>Future Of Tourism</i>
            </b>
          </p>
        </div>

        <div id="dmc-cta" className="guide-cta">
          <div className="dmc-bgImage">
            <div className="cta-contents">
              <h3>Travel Agent or DMC?</h3>
              <h4>Find your guide today</h4>
              <button
                onClick={() =>
                  window.open(
                    "https://forms.gle/6hDvXq3wpxRBszM39",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Register
              </button>
            </div>
            <div className="cta-contents"></div>
          </div>
        </div>

        <div id="guide-cta" className="guide-cta">
          <div className="guide-bgImage">
            <div className="cta-contents">
              <h3>Want to become an Honest Guide?</h3>
              <button
                onClick={() =>
                  window.open(
                    "https://forms.gle/iScY2Gfs6ab9hPcU8",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Sign up
              </button>
            </div>
            <div className="cta-contents"></div>
          </div>
        </div>
      </div>

      <div className="faq-div">
        <div className="faq-header-row">
          <div className="faq-header">
            <h3>Frequently Asked Questions</h3>
          </div>
        </div>

        <div className="faqs">
          {faqs.map((faq, index) => (
            <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
