import axios from "../api/axios";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const REFRESHTOKEN_URL = "/api/token/refresh";

const useRefreshToken = () => {
  const { auth, setAuth } = useContext(AuthContext);

  const refresh = async () => {
    try {
      const response = await axios.post(REFRESHTOKEN_URL, {
        refresh: auth.refresh_token,
      });
      setAuth({
        ...auth,
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
      });
      localStorage.setItem(
        "auth",
        JSON.stringify({
          ...auth,
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        })
      );
      return response.data.access_token;
    } catch (error) {
      console.error("useRefreshToken: Error in refresh", error);
    }
  };

  return refresh;
};

export default useRefreshToken;
