import React, { useState } from "react";
import Navbar from "../components/navbar guide";
import Footer from "../components/footer";
import ToursDetails from "../components/guide tours/guide tours";
import AddTour from "../components/guide tours/add tours";

const GuideTours = () => {
  const [activeSection, setActiveSection] = useState("Guide Tours");
  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const toursMenu = ["Guide Tours", "Add Tour"];

  return (
    <div>
      <Navbar />
      <h1 style={{ paddingLeft: "50px", fontWeight: "normal" }}>Tours</h1>
      <div className="main-window">
        <div className="side-menu">
          {toursMenu.map((text, index) => (
            <button
              className="side-menu-button"
              onClick={() => handleButtonClick(text)}
              key={index}
            >
              {text}
            </button>
          ))}
        </div>
        <div className="details-pane">
          <div className="details-pane-heading">
            <h2>{activeSection}</h2>
          </div>
          <div className="details-pane-contents">
            {activeSection === "Guide Tours" && <ToursDetails />}
            {activeSection === "Add Tour" && <AddTour />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GuideTours;
