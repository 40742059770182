import React, { useState } from "react";
import axios from "../../api/axios"; // Import your axios instance for API requests

const SendMessageModal = ({ isOpen, onClose, tourName, bookingId }) => {
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the message to the backend
      await axios.post(`/api/send-message`, {
        bookingId,
        message,
      });

      // Close the modal upon successful submission
      onClose();
    } catch (error) {
      // Handle error if the message cannot be sent
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className={`modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="box">
          <header className="modal-title">
            <h4>{tourName} Message</h4>
            <button className="button" aria-label="close" onClick={onClose}>
              <i class="fa-solid fa-x"></i>
            </button>
          </header>

          <form onSubmit={handleSubmit}>
            <div className="field">
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Type message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button type="submit" className="button is-primary">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SendMessageModal;
