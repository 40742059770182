import React from "react";

const accountSection = () => {
  return (
    <div className="account-page">
      <p>Request account deletion</p>
      Enter password: <input type="password" />
      <br />
      <br />
      <input type="submit" className="button" value="Request" required />
    </div>
  );
};

export default accountSection;
