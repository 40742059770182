import React, { useEffect, useState, useContext } from "react";
import axios from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

const GuideReviews = () => {
  const auth = useContext(AuthContext);
  const loggedInUserId = auth.auth.userID;

  const reviews_URL = "/api/client_reviews?format=json";
  const bookings_URL = `/api/guide_bookings/${loggedInUserId}/`; // Update the URL
  const tours_URL = "/api/tours?format=json";

  const [reviews, setReviews] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [tourDetails, setTourDetails] = useState({});

  useEffect(() => {
    axios.get(reviews_URL).then((response) => {
      setReviews(response.data);
    });
  }, []);

  useEffect(() => {
    axios.get(bookings_URL).then((response) => {
      setBookings(response.data.bookings);
    });
  }, [bookings_URL]);

  useEffect(() => {
    axios.get(tours_URL).then((response) => {
      const tours = response.data.tours;
      const tourDetailsMap = {};
      tours.forEach((tour) => {
        tourDetailsMap[tour.id] = tour.name;
      });
      setTourDetails(tourDetailsMap);
    });
  }, []);

  const guideBookings = bookings.map((booking) => booking.id);

  const guideReviews = reviews.filter((review) =>
    guideBookings.includes(review.booking)
  );

  return (
    <div>
      <div className="scrollable">
        {guideReviews.length > 0 ? (
          guideReviews.map((review) => (
            <div className="review-card" key={review.id}>
              <p>
                <b>Tour: </b>
                {tourDetails[review.booking]}
              </p>
              <p>
                <b>Rating: </b>
                {review.rating}
              </p>
              <p>
                <b>Comment: </b>
                {review.comment}
              </p>
            </div>
          ))
        ) : (
          <p>No reviews yet.</p>
        )}
      </div>
    </div>
  );
};

export default GuideReviews;
