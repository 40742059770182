import React, { useContext } from 'react';
import Admin from '../components/admin';
import Guide from '../components/guide';
import Dmc from '../components/dmc';
import Home from '../components/home';
import AuthContext from '../context/AuthProvider';

const DEFAULT_PAGES = {
  'admin': <Admin />,
  'guide': <Guide />,
  'dmc': <Dmc />,
};

const Main = () => {
  const { roles } = useContext(AuthContext);

  const defaultPage = DEFAULT_PAGES[roles] || <Home />;

  return defaultPage;
};

export default Main;
