import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../images/Asset 1.svg";

import axios from "../api/axios";
const LOGIN_URL = "api/token";

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const access_token = response?.data?.access_token;
      const refresh_token = response?.data?.refresh_token;
      const roles = response?.data?.roles;
      const userID = response?.data?.id;
      setAuth({ userID, roles, access_token, refresh_token });

      // Save auth data in localStorage
      localStorage.setItem(
        "auth",
        JSON.stringify({ userID, roles, access_token, refresh_token })
      );

      setEmail("");
      setPassword("");

      if (roles.includes("admin")) {
        navigate("/admin", { replace: true });
      } else if (roles.includes("guide")) {
        navigate("/guide", { replace: true });
      } else if (roles.includes("dmc")) {
        navigate("/dmc", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Email or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="login">
      <header className="home-nav">
        <Link to="/">
          <img src={logo} className="home-logo" alt="logo" />
        </Link>
      </header>
      <div className="loginForm">
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <label>
            <p>Email</p>
            <input
              type="text"
              id="email"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
          </label>
          <label>
            <p>Password</p>
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
            />
          </label>
          <br />
          <br />
          <div>
            <button className="button" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
