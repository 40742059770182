import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";
import axios from "axios";

const baseURL = "http://127.0.0.1:8000/api/guides?format=json";

const Guides = () => {
  const [guides, setGuides] = useState(null);

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setGuides(response.data.guides);
    });
  }, []);

  return (
    <div>
      <header className="home-nav">
        <a href="/">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
      </header>
      <div>
        <h1>Guides</h1>
        <div>
          {guides
            ? guides.map((guide) => {
                return (
                  <div key={guide.id}>
                    <Link to="/tour">
                      <h2>{guide.name}</h2>
                    </Link>
                    <p>{guide.title}</p>
                    <br />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Guides;
