import React from 'react'
import Navbar from '../components/navbar'

const AdminMessages = () => {
  return (
    <div>
        <Navbar />
        <div className="admin-panel" >
            <h1>Messages</h1>
        </div>
    </div>
  )
}

export default AdminMessages