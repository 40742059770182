import React, { useState, useEffect, useContext } from "react";
import axios from "../api/axios";
import Navbar from "./navbar guide";
import "../css/guide.css";
import AuthContext from "../context/AuthProvider"; // Import the AuthContext

const Guide = () => {
  const [toursCount, setToursCount] = useState(0);
  const [bookingsCount, setBookingsCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const auth = useContext(AuthContext);
  const loggedInUserId = auth.auth.userID;

  useEffect(() => {
    axios.get(`/api/guide_id/${loggedInUserId}/`).then((response) => {
      const guideId = response.data.guide_id;

      // Fetch the number of tours associated with the guide
      axios.get(`/api/guide_tours/${guideId}/`).then((response) => {
        setToursCount(response.data.tours.length);
        //console.log(response.data.tours);
      });

      // Fetch the bookings associated with the guide's tours
      axios.get(`/api/guide_bookings/${guideId}/`).then((response) => {
        setBookingsCount(response.data.bookings.length);
        //console.log(response.data.bookings);

        // Calculate the total revenue based on booking fees
        const bookings = response.data.bookings;
        const totalRevenue = bookings.reduce(
          (total, booking) => total + parseFloat(booking.booking_fee),
          0
        );
        setTotalRevenue(totalRevenue);
      });
    });
  }, [loggedInUserId]);

  return (
    <div className="GuidePage">
      <Navbar />
      <div className="guide-homepage">
        <div className="guide-dashboard">
          <div className="guide-dashboard-header">
            <h1>Guide Dashboard</h1>
          </div>

          <div className="notice-cards-section">
            <div className="notice-card">
              <div>
                <p>Revenue</p>
                <br />
                <h2>R {totalRevenue}</h2>
              </div>
              <div className="notice-card-icon">
                <i
                  style={{ padding: "10px", fontSize: "25px" }}
                  className="fa-solid fa-money-bill-1-wave"
                ></i>
              </div>
            </div>
            <div className="notice-card">
              <div>
                <p>Bookings</p>
                <br />
                <h2>{bookingsCount}</h2>
              </div>
              <div className="notice-card-icon">
                <i
                  style={{ padding: "15px", fontSize: "25px" }}
                  className="fa-solid fa-receipt"
                ></i>
              </div>
            </div>
            <div className="notice-card">
              <div>
                <p>Tours</p>
                <br />
                <h2>{toursCount}</h2>
              </div>
              <div className="notice-card-icon">
                <i
                  style={{ padding: "15px", fontSize: "25px" }}
                  className="fa-solid fa-person-walking"
                ></i>
              </div>
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ padding: "20px" }}>
              <div className="guide-dashboard-message">
                <span>
                  <i className="fa-solid fa-circle-info"></i> Notifications
                  apear hear{" "}
                </span>
              </div>
              <div>
                <p>Tour activity</p>
                <p>Bookins</p>
                <p>Reviews</p>
              </div>
            </div>
            <div className="guide-side-panel">
              <div className="guide-side-panel-header">
                <h4>Guide Side Panel</h4>
              </div>
              <div className="guide-side-panel-body">
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
