import { useState } from "react";
import Navbar from "../components/navbar dmc";
import UpcomingEvent from "../components/dmc bookings/upcomingEvents";
import PastEvents from "../components/dmc bookings/pastEvents";
import CancelledEvents from "../components/dmc bookings/cancelledEvents";
import EventCalendar from "../components/dmc bookings/eventCalendar";
import Reviews from "../components/dmc bookings/reviews";

const Bookings = () => {
  const [activeSection, setActiveSection] = useState("Upcoming Events");
  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const bookingsMenu = [
    "Upcoming Events",
    "Past Events",
    "Cancelled Events",
    "Calendar",
    "Reviews",
  ];

  return (
    <div>
      <Navbar />
      <h1 style={{ paddingLeft: "40px" }}>Bookings</h1>
      <div className="main-window">
        <div className="side-menu">
          {bookingsMenu.map((text, index) => (
            <button
              className="side-menu-button"
              onClick={() => handleButtonClick(text)}
              key={index}
            >
              {text}
            </button>
          ))}
        </div>
        <div className="details-pane">
          <div className="details-pane-heading">
            <h2>{activeSection}</h2>
          </div>
          <div className="details-pane-contents">
            {activeSection === "Upcoming Events" && <UpcomingEvent />}
            {activeSection === "Past Events" && <PastEvents />}
            {activeSection === "Cancelled Events" && <CancelledEvents />}
            {activeSection === "Calendar" && <EventCalendar />}
            {activeSection === "Reviews" && <Reviews />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
