import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import ChatBoxModal from "./chatBoxModal";

const DmcConversations = () => {
  const tours_URL = "/api/tours?format=json";
  const messages_URL = "/api/messages?format=json";

  const [tourDetails, setTourDetails] = useState({});

  const [activeChats, setActiveChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);

  useEffect(() => {
    axios.get(messages_URL).then((response) => {
      // Filter distinct bookings to get active chats
      //console.log(response.data);
      const bookingsWithChats = [
        ...new Set(response.data.map((msg) => msg.booking)),
      ];
      setActiveChats(bookingsWithChats);
    });
  }, []);

  useEffect(() => {
    axios.get(tours_URL).then((response) => {
      const tours = response.data.tours;
      const tourDetailsMap = {};
      tours.forEach((tour) => {
        tourDetailsMap[tour.id] = {
          name: tour.name,
        };
      });
      setTourDetails(tourDetailsMap);
    });
  }, []);

  const handleChatSelect = (bookingId) => {
    setSelectedChat(bookingId);

    // Fetch chat messages for the selected chat
    axios.get(messages_URL).then((response) => {
      const chatMessages = response.data.filter(
        (msg) => msg.booking === bookingId
      );
      setChatMessages(chatMessages);
    });
  };

  return (
    <div>
      <div className="scrollable">
        {activeChats.length > 0 ? (
          activeChats.map((bookingId) => (
            <div
              className="chat-preview"
              key={bookingId}
              onClick={() => handleChatSelect(bookingId)}
            >
              <h4>
                <b>Chat</b>: {bookingId}
                {console.log(tourDetails)}
              </h4>
            </div>
          ))
        ) : (
          <p>No active chats. Start a new chat.</p>
        )}
      </div>

      {selectedChat && (
        <ChatBoxModal
          isOpen={true} // Replace with appropriate logic to control modal open/close
          onClose={() => setSelectedChat(null)} // Close the modal when needed
          chatMessages={chatMessages} // Pass chat messages as props
        />
      )}
    </div>
  );
};

export default DmcConversations;
