import React, { useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from  '../images/Asset 1.svg';
import AuthContext from '../context/AuthProvider'

const Navbar = () => {
  let {auth, logoutUser } = useContext(AuthContext)

  const navigate = useNavigate();
    const navToMessages = () => {
      navigate('/admin-messages');
    };

    const navToSettings = () => {
      navigate('/admin-settings');
    };

  return(
    <header className="dmc-nav">
        <Link to="/admin"><img src={logo} className="App-logo" alt="logo" /></Link>
        { auth && 
          <div className="nav-btn">
          <button name="Messages" onClick={navToMessages} ><i className="fa-solid fa-comments"></i></button>
          <button name="Settings" onClick={navToSettings} ><i className="fa-solid fa-user"></i></button>
          <button onClick={logoutUser}>Logout</button>
          </div>
        }
      </header> 
  );
};

export default Navbar;