import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/navbar dmc";
import Footer from "../components/footer";
import axios, { BASE_URL } from "../api/axios";
import "../css/dmc.css";

const guidesURL = "api/guides?format=json";
const cityURL = "api/cities?format=json";

const DMC = () => {
  const [guides, setGuides] = useState([]);
  const [cities, setCities] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);

  // Split cities into firstTwo and remaining arrays
  const firstTwo = cities.slice(0, 6);
  const remaining = cities.slice(6);

  useEffect(() => {
    axios.get(guidesURL).then((response) => {
      setGuides(response.data);
    });

    axios.get(cityURL).then((response) => {
      setCities(response.data.cities);
    });
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm !== "") {
      const fetchResults = async () => {
        try {
          const response = await axios.get(
            `api/cities/search/?search=${searchTerm}`
          );
          setSearchResults(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCityClick = (cityId) => {
    navigate(`/city/${cityId}`);
  };

  return (
    <div className="DMC">
      <Navbar />
      <div className="dmc-hero">
        <div className="hero-contents">
          <div className="hero-title">
            <h1>Honest Travel Guides</h1>
            <h2>Find your next tour guide, the Honest Way</h2>
          </div>
          <div className="search-div">
            <div className="search-form">
              <input
                type="search"
                className="textbox"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Choose your city..."
              />
              <button className="searchbox">
                <i className="fa fa-search"></i>
              </button>
              <br />
              <div className="search-results-container">
                <ul className="search-results">
                  {searchResults.map((result) => (
                    <li
                      key={result.id}
                      onClick={() => handleCityClick(result.id)}
                    >
                      {result.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="featured-div">
        <h2>Featured guides</h2>
        <div className="featured-row">
          {guides
            ? guides.map((guide) => {
                return (
                  <div className="featured-column" key={guide.id}>
                    <Link to={"/guides/" + guide.id}>
                      <img
                        className="tour-image"
                        src={`${BASE_URL}${guide.profile_pic}`}
                        alt={guide.name + "-image"}
                      />
                    </Link>
                    <p className="featured-tour-title">{guide.name}</p>
                    <b>
                      {guide.user.first_name} {guide.user.last_name}
                    </b>
                    <p>{guide.title}</p>
                  </div>
                );
              })
            : null}
        </div>
      </div>

      <div className="cities-div">
        <h2>Featured cities</h2>
        <div className="city-cards-row">
          {firstTwo.map((city) => (
            <div className="city-card" key={city.id}>
              <div className="city-image-container">
                <a href={"/city/" + city.id}>
                  <img
                    className="city-img"
                    src={`${BASE_URL}${city.image}`}
                    alt={`${city.name}`}
                  />
                </a>
              </div>
              <span className="city-card-title">{city.name}</span>
            </div>
          ))}
          {isExpanded &&
            remaining.map((city) => (
              <div className="city-card" key={city.id}>
                <div className="city-image-container">
                  <a href={"/city/" + city.id}>
                    <img
                      className="city-img"
                      src={`${BASE_URL}${city.image}`}
                      alt={`${city.name}`}
                    />
                  </a>
                </div>
                <span className="city-card-title">{city.name}</span>
              </div>
            ))}
        </div>
        {remaining.length > 0 && (
          <button className="button" onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? "Collapse" : "View More"}
          </button>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default DMC;
