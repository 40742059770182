import React from "react";
import Navbar from "../components/navbar guide";
import Footer from "../components/footer";
import Calendar from "../components/guide calendar/Calendar";

const GuideCalendar = () => {
  return (
    <div>
      <Navbar />
      <h1 style={{ paddingLeft: "50px", fontWeight: "normal" }}>
        Calendar & Events
      </h1>
      <Calendar />
      <Footer />
    </div>
  );
};

export default GuideCalendar;
