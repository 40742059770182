import React from "react";
import logo from "../images/Asset 1.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-contents">
        <div className="footer-row">
          <div className="footer-column">
            <div className="footer-column-contents">
              <img src={logo} alt="logo" />
              <p>
                Experience tours like never before.
                <br />
                Travel The Honest Way.
              </p>
              <div></div>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-column-contents">
              <h4>Information</h4>
              <ul>
                <li>
                  <Link to="">FAQ</Link>
                </li>
                <li>
                  <Link to="">Blog</Link>
                </li>
                <li>
                  <Link to="">About Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-column">
            <div>
              <h4>Guides</h4>
              <ul>
                <li>
                  <Link to="">Become a guide</Link>
                </li>
                <li>
                  <Link to="">Tips for Guides</Link>
                </li>
                <li>
                  <Link to="">Guide support</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-column">
            <div>
              <h4>Help Center</h4>
              <ul>
                <li>
                  <Link to="">Contact us</Link>
                </li>
                <li>
                  <Link to="">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="">Privacy policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>2023 © Honest travel Guides</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
