import React, { useContext } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from  '../images/Asset 1.svg';
import AuthContext from '../context/AuthProvider'

const Navbar = () => {
  let {auth, logoutUser} = useContext(AuthContext)

  const navigate = useNavigate();
    const navToBookings = () => {
        navigate('/guide-bookings');
      };

      const navToMessages = () => {
        navigate('/guide-messages');
      };

      const navToSettings = () => {
        navigate('/guide-settings');
      };

      const navToCalendar = () => {
        navigate('/guide-calendar');
      };

      const navToTours = () => {
        navigate('/guide-tours');
      };

  return(
    <header className="dmc-nav">
        <Link to="/guide"><img src={logo} className="App-logo" alt="logo" /></Link>
        { auth && 
          <div className="nav-btn">
          <button name="Calendar" onClick={navToCalendar} >Calendar <i className="fa-solid fa-calendar"></i></button>
          <button name="Bookings" onClick={navToBookings} >Bookings <i className="fa-solid fa-receipt"></i></button>
          <button name="Tours" onClick={navToTours} >Tours <i className="fa-solid fa-person-walking"></i></button>
          <button name="Messages" onClick={navToMessages} >Messages <i className="fa-solid fa-comments"></i></button>
          <button name="Settings" onClick={navToSettings} ><i className="fa-solid fa-user"></i></button>
          <button onClick={logoutUser}>Logout</button>
          </div>
        }
      </header> 
  );
};

export default Navbar;