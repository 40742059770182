import React from "react";
import Calendar from "../guide calendar/Calendar";

const EventCalendar = () => {
  return (
    <div>
      <Calendar />
    </div>
  );
};

export default EventCalendar;
