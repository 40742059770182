// ChatBoxModal.js
import React from "react";

const ChatBoxModal = ({ isOpen, onClose, chatMessages }) => {
  return (
    <div className="chat-box-background">
      <div className={`chat-box-modal ${isOpen ? "is-active" : ""}`}>
        <div className="chat-box">
          <header className="chat-header">
            {/* Header content (e.g., chat participant names) */}
            <button className="button" aria-label="close" onClick={onClose}>
              <i class="fa-solid fa-x"></i>
            </button>
          </header>
          <div className="chat-messages">
            {/* Display chat messages here */}
            {chatMessages.map((message) => (
              <div key={message.id} className="chat-message">
                {/* Render individual chat messages */}
                <p>{message.text}</p>
              </div>
            ))}
          </div>
          <footer className="chat-footer">
            {/* Input field for sending messages */}
            <input type="text" placeholder="Type a message..." />
            <button className="button">Send</button>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxModal;
