import { useParams, useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "../components/navbar dmc";
import axios, { BASE_URL } from "../api/axios";

const City = () => {
  const { id } = useParams();
  const [cityDetails, setCityDetails] = useState([]);
  const navigate = useNavigate();
  const [cityInfo, setCityInfo] = useState([]);

  useEffect(() => {
    const cityURL = BASE_URL + "api/cities/" + id + "?format=json";
    axios.get(cityURL).then((response) => {
      setCityInfo(response.data.city);
    });

    const URL = BASE_URL + "api/guides?format=json";
    axios.get(URL).then((response) => {
      setCityDetails(response.data);
    });
  }, [id, navigate]);

  const cityGuides = cityDetails.filter((guide) => guide.city === parseInt(id));

  return (
    <div>
      <Navbar />
      <div style={{ padding: "30px" }}>
        <div>
          <img
            className="city-banner"
            src={BASE_URL + cityInfo.image}
            alt={cityInfo.name}
          />
        </div>
        <h1>{cityInfo.name}</h1>
        <div style={{ margin: "20px" }} className="featured-row">
          {cityGuides
            ? cityGuides.map((guide) => {
                return (
                  <div className="featured-column" key={guide.id}>
                    <Link to={"/guides/" + guide.id}>
                      <img
                        className="tour-image"
                        src={BASE_URL + guide.profile_pic}
                        alt={guide.title + "- image"}
                      />
                    </Link>
                    <p className="featured-tour-title">{guide.first_name}</p>
                    <p>{guide.title}</p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default City;
