import React from "react";

const DmcMessagesSettings = () => {
  return (
    <div>
      <h2>Email notications</h2>
      <div className="container">
        Yes &nbsp;
        <label class="switch">
          <input type="checkbox" />
          <span class="slider round"></span>
        </label>
        &nbsp;No
      </div>
      <h2>Accept Mesages</h2>
      <div className="container">
        Yes &nbsp;
        <label class="switch">
          <input type="checkbox" />
          <span class="slider round"></span>
        </label>
        &nbsp;No
      </div>
    </div>
  );
};

export default DmcMessagesSettings;
