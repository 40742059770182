import React, { useEffect, useState } from "react";
import axios from "../../api/axios";

const Reviews = () => {
  const reviewsURL = "/api/client_reviews?format=json";
  const bookingsURL = "/api/bookings?format=json"; // URL to fetch booking details
  const toursURL = "/api/tours?format=json"; // URL to fetch the list of tours

  const [reviews, setReviews] = useState(null);
  const [tourDetails, setTourDetails] = useState({});
  const [bookingDetails, setBookingDetails] = useState({});

  useEffect(() => {
    axios.get(toursURL).then((response) => {
      const tours = response.data.tours;
      const tourDetailsMap = {};
      tours.forEach((tour) => {
        tourDetailsMap[tour.id] = {
          name: tour.name,
        };
      });
      setTourDetails(tourDetailsMap);
    });
  }, []);

  useEffect(() => {
    // Fetch booking details to map booking IDs to tour IDs
    axios.get(bookingsURL).then((response) => {
      const bookingDetailsMap = {};
      response.data.forEach((booking) => {
        bookingDetailsMap[booking.id] = {
          tourId: booking.tour,
        };
      });
      setBookingDetails(bookingDetailsMap);
    });

    // Fetch reviews
    axios.get(reviewsURL).then((response) => {
      setReviews(response.data);
    });
  }, [toursURL, bookingsURL, reviewsURL]);

  // Function to convert numerical rating to stars
  const getStars = (rating) => {
    const starCount = Math.round(rating); // Round the rating to nearest integer
    return Array.from({ length: starCount }, (_, index) => (
      <span key={index} role="img" aria-label="star">
        ⭐
      </span>
    ));
  };

  return (
    <div>
      {reviews ? (
        reviews.map((review) => {
          const bookingDetailsForReview = bookingDetails[review.booking] || {};
          const tourId = bookingDetailsForReview.tourId;

          const tourDetailsForReview = tourDetails[tourId] || {};
          const tourName = tourDetailsForReview.name || "Unknown Tour"; // Provide a default if tour name is not found

          return (
            <div className="review-cell" key={review.id}>
              <h4>{tourName}</h4>
              <div>{getStars(review.rating)}</div>
              <p>{review.review}</p>
            </div>
          );
        })
      ) : (
        <div>
          No reviews yet. <a href="Past Bookings">Write a review now!</a>
        </div>
      )}
    </div>
  );
};

export default Reviews;
