import React from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import "./App.css";
import Main from "./pages/main.js";
import AboutUs from "./pages/aboutUs.js";
import Bookings from "./pages/bookings.js";
import BookTour from "./pages/bookTour.js";
import Messages from "./pages/messages.js";
import Settings from "./pages/settings.js";
import City from "./pages/city.js";
import Tours from "./pages/tours.js";
import TourDetails from "./pages/tourDetails.js";
import Guides from "./pages/guides";
import GuideDetails from "./pages/guideDetails";
import NotFound from "./pages/notFound.js";
import LoginPage from "./pages/login.js";
import LogoutPage from "./pages/logout.js";
import SignupPage from "./pages/signUp.js";
import Layout from "./components/Layout";
import Unauthorized from "./pages/Unauthorized";
import Admin from "./components/admin";
import Guide from "./components/guide";
import Dmc from "./components/dmc";
import AdminMessages from "./pages/adminMessages";
import AdminSettings from "./pages/adminSettings";
import GuideCalendar from "./pages/guideCalendar";
import GuideBookings from "./pages/guideBookings";
import GuideTours from "./pages/guideTours";
import GuideMessages from "./pages/guideMessages";
import GuideSettings from "./pages/guideSettings";

const ROLES = {
  Admin: "admin",
  Guide: "guide",
  DMC: "dmc",
};

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Layout />}>
        {/* Public routes */}
        <Route path="/" exact element={<Main />} />
        <Route path="/about" exact element={<AboutUs />} />
        <Route path="/tours" element={<Tours />} />
        <Route path="/tours/:id" element={<TourDetails />} />
        <Route path="/guides" element={<Guides />} />
        <Route path="/guides/:id" element={<GuideDetails />} />
        <Route path="/city/:id" element={<City />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Authentication routes */}
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/logout" element={<LogoutPage />} />
        <Route exact path="/signup" element={<SignupPage />} />

        <Route element={<PersistLogin />}>
          {/* Private routes */}
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin-messages" element={<AdminMessages />} />
            <Route path="/admin-settings" element={<AdminSettings />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.DMC]} />}>
            <Route path="/dmc" element={<Dmc />} />
            <Route path="/book" element={<BookTour />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/settings" element={<Settings />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Guide]} />}>
            <Route path="/guide" element={<Guide />} />
            <Route path="/guide-calendar" element={<GuideCalendar />} />
            <Route path="/guide-bookings" element={<GuideBookings />} />
            <Route path="/guide-tours" element={<GuideTours />} />
            <Route path="/guide-messages" element={<GuideMessages />} />
            <Route path="/guide-settings" element={<GuideSettings />} />
          </Route>
        </Route>

        {/* 404 routes */}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
