import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../context/AuthProvider";
import axios from "../../api/axios";

const GuideTours = () => {
  const auth = useContext(AuthContext);
  const loggedInUserId = auth.auth.userID;
  const [guideId, setGuideId] = useState();

  const [guideTours, setGuideTours] = useState([]);

  useEffect(() => {
    axios.get(`/api/guide_id/${loggedInUserId}/`).then((response) => {
      setGuideId(response.data.guide_id);
    });
  }, [loggedInUserId]);

  useEffect(() => {
    const tours_URL = "/api/guide_tours/";

    axios.get(`${tours_URL}${guideId}/`).then((response) => {
      console.log(response);
      setGuideTours(response.data.tours);
    });
  }, [guideId]);

  return (
    <div>
      <h2>Your Tours</h2>
      <div className="guide-tours-list">
        {guideTours.length > 0 ? (
          guideTours.map((tour) => (
            <div className="guide-tour" key={tour.id}>
              <h3>{tour.name}</h3>
              <p>{tour.description}</p>
              {/* You can add more tour details here */}
            </div>
          ))
        ) : (
          <p>No tours found.</p>
        )}
      </div>
    </div>
  );
};

export default GuideTours;
