import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "../components/navbar dmc";
import axios, { BASE_URL } from "../api/axios";

const TourDetails = () => {
  const { id } = useParams();
  const [isActive, setIsActive] = useState(false);
  const [guideDetails, setGuideDetails] = useState([]);
  const [tours, setTours] = useState([]);

  const guidesURL = "api/guides/" + id + "?format=json";
  const toursURL = BASE_URL + "api/tours?format=json";

  useEffect(() => {
    axios.get(guidesURL).then((response) => {
      setGuideDetails(response.data);
      //console.log(response.data);
    });

    axios.get(toursURL).then((response) => {
      setTours(response.data.tours);
      //console.log(response.data.tours);
    });
  }, [guidesURL, toursURL]);

  const guideTours = Array.isArray(tours)
    ? tours.filter((tour) => tour.guide.id === parseInt(id))
    : [];

  /*const guideTours = tours.filter(tour =>
        tour.guide.id === parseInt(id)
    );*/

  const listTours = guideTours.map((tour) => (
    <div className="guide-tours-card" key={tour.id}>
      <Link to={"/tours/" + tour.id}>
        <img
          className="tour-image"
          src={BASE_URL + tour.cover_image}
          alt={tour.name + "- image"}
        />
        <p>
          <b>{tour.name}</b>
          <br />
          {tour.description}
        </p>
      </Link>
    </div>
  ));

  return (
    <div>
      <Navbar />
      <div>
        <div className="guide-details">
          <h1>Guide Details</h1>
          {guideDetails && (
            <div>
              {/*<div className="guide-banner">
                    <img 
                        className="guide-cover-image"
                        src={BASE_URL+guideDetails.cover_image} 
                        alt={guideDetails.title} 
                    />
                    </div>*/}
              <div className="guide-profile">
                <div>
                  <img
                    style={{ height: "200px" }}
                    src={BASE_URL + guideDetails.profile_pic}
                    alt={guideDetails.title}
                  />
                </div>

                <div>
                  <h2>
                    {guideDetails.user?.first_name}{" "}
                    {guideDetails.user?.last_name}
                  </h2>
                  <p>{guideDetails.title}</p>
                  <p>{guideDetails.description}</p>
                  <p>{guideDetails.location}</p>
                </div>
              </div>
            </div>
          )}
          <div>
            <form>
              <div className="accordion">
                <div className="accordion-item">
                  <div
                    className="accordion-title"
                    onClick={() => setIsActive(!isActive)}
                  >
                    <div>
                      <b>Custom itinerary</b>
                    </div>
                    <div>{isActive ? "-" : "+"}</div>
                  </div>
                  {isActive && (
                    <div className="accordion-content">
                      <label>
                        <b>Itinerary</b>
                      </label>
                      <br />
                      <textarea
                        type="textarea"
                        placeholder="Specifics on your intenirary..."
                        className="Itenirary"
                        rows="10"
                      />
                      <br />
                      <label>
                        <b>Offer</b>
                      </label>
                      <div>
                        <span className="itinerary-currency">R</span>
                        <input
                          type="number"
                          className="intinerary-amount"
                          placeholder="Amount"
                        />
                      </div>
                      <br />
                      <button className="button">Offer</button>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div>
            <h3>Tours</h3>
            <div className="guide-tours-row">{listTours}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourDetails;
