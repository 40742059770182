import { useParams, useNavigate, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Navbar from "../components/navbar dmc";
import axios from "axios";

const TourDetails = () => {
  const { id } = useParams();
  const [tourDetails, setTourDetails] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const URL = "http://127.0.0.1:8000/api/tours/" + id + "?format=json";
    axios.get(URL).then((response) => {
      if (response.status === 404) {
        navigate("/404");
      }
      setTourDetails(response.data.tour);
    });
  }, [id, navigate]);

  return (
    <div>
      <Navbar />
      <div className="tour-details">
        <div>
          <h1>Tour Details</h1>
          <div style={{ margin: "10px", display: "flex" }}>
            <div style={{ width: "50%" }}>
              {tourDetails && (
                <div>
                  <h2>{tourDetails?.name}</h2>
                  <p style={{ width: "95%" }}>{tourDetails?.description}</p>
                  <br />
                  <b>R {tourDetails?.price} each</b>
                  <br />
                  <b>Max group size: {tourDetails.capacity} people</b>
                  <br />
                  <b>{tourDetails?.location}</b>
                  <br />
                  <b>{tourDetails?.duration} hours</b>
                  <br />
                  <b>{tourDetails?.status}</b>
                  <br />
                </div>
              )}

              <Link
                to="/book"
                state={{ tourDetails: tourDetails }}
                className="button"
              >
                Book now
              </Link>
            </div>
            <div style={{ width: "50%" }}>
              <img
                style={{ width: "100%" }}
                src={"http://127.0.0.1:8000/" + tourDetails?.cover_image}
                alt={tourDetails?.name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourDetails;
