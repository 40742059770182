import React, { useEffect, useState, useContext } from "react";
import axios, { BASE_URL } from "../../api/axios";
import AuthContext from "../../context/AuthProvider"; // Import the AuthContext

const UpcomingEvents = () => {
  const tours_URL = "/api/guide_tours/";
  const bookings_URL = "/api/guide_bookings/";

  const auth = useContext(AuthContext); // Access the AuthContext
  const loggedInUserId = auth.auth.userID; // Assuming you have the guide's ID in userID
  const [guideId, setGuideId] = useState(loggedInUserId);

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [tourDetails, setTourDetails] = useState({});

  useEffect(() => {
    axios.get(`/api/guide_id/${loggedInUserId}/`).then((response) => {
      setGuideId(response.data.guide_id);
    });
  }, [loggedInUserId]);

  useEffect(() => {
    axios.get(`${tours_URL}${guideId}/`).then((response) => {
      const tours = response.data.tours;
      const tourDetailsMap = {};
      tours.forEach((tour) => {
        tourDetailsMap[tour.id] = {
          name: tour.name,
          coverImage: tour.cover_image, // Store cover image URL
        };
      });
      setTourDetails(tourDetailsMap);
    });
  }, [guideId]);

  useEffect(() => {
    axios.get(`${bookings_URL}${guideId}/`).then((response) => {
      const today = new Date();
      const upcomingEventsData = response.data.bookings.filter(
        (booking) => new Date(booking.date) >= today
      );
      setUpcomingEvents(upcomingEventsData);
    });
  }, [guideId]);

  return (
    <div>
      <div className="scrollable">
        {upcomingEvents.length > 0 ? (
          upcomingEvents.map((booking) => (
            <div className="booking-card" key={booking.id}>
              <div>
                <img
                  src={BASE_URL + tourDetails[booking.tour]?.coverImage}
                  alt={booking.tour.name}
                />
              </div>
              <div>
                <span>
                  <h3>{tourDetails[booking.tour]?.name || booking.tour}</h3>
                </span>
                <p>
                  <b>Booked date: </b>
                  {booking.date}
                </p>
                <p>
                  <b>Guests: </b>
                  {booking.num_guests}
                </p>
                <p>
                  <b>Booking status: </b>
                  <select name="tour-status" id="tour-status">
                    <option value="pending">Pending</option>
                    <option value="confirmed">Accept</option>
                    <option value="cancelled">Reject</option>
                  </select>
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No upcoming events.</p>
        )}
      </div>
    </div>
  );
};

export default UpcomingEvents;
