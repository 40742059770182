import React from "react";

const passwordSection = () => {
  return (
    <div className="password-page">
      <p>Change password</p>
      <input type="password" placeholder="Old password" />
      <br />
      <input type="password" placeholder="New password" />
      <br />
      <button className="button" type="submit">
        Save
      </button>
    </div>
  );
};

export default passwordSection;
