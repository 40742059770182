import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png";
import axios from "axios";

const baseURL = "https://127.0.0.1:8000/api/tours?format=json";

const Tours = () => {
  const [tours, setTours] = useState(null);

  useEffect(() => {
    axios.get(baseURL).then((response) => {
      setTours(response.data.tours);
      console.log(response.data.tours);
    });
  }, []);

  return (
    <div>
      <header className="home-nav">
        <a href="/">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
      </header>
      <div>
        <h1>Tours</h1>
        <div>
          {tours
            ? tours.map((tour) => {
                return (
                  <div key={tour.id}>
                    <Link to="/tour">
                      <h2>{tour.name}</h2>
                    </Link>
                    <p>{tour.description}</p>
                    <br />
                    <b>R {tour.price}</b>
                    <br />
                    <b>{tour.capacity} people</b>
                    <br />
                    <b>{tour.location}</b>
                    <br />
                    <b>{tour.duration} hours</b>
                    <br />
                    <b>{tour.status}</b>
                    <br />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default Tours;
