import React, { useState } from "react";
import axios from "../../api/axios"; // Import your axios instance for API requests

const AddReviewModal = ({ isOpen, onClose, tourName, bookingId }) => {
  const [message, setMessage] = useState("");
  const [selectedRating, setSelectedRating] = useState(1);

  const [hoverRating, setHoverRating] = useState(0);

  const handleMouseOver = (star) => {
    setHoverRating(star);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (star) => {
    setSelectedRating(star);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the message and selected rating to the backend
      await axios.post(`/api/client_reviews`, {
        review: message,
        rating: selectedRating,
        booking: bookingId,
      });

      // Close the modal upon successful submission
      onClose();
    } catch (error) {
      // Handle error if the message cannot be sent
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className={`modal ${isOpen ? "is-active" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">
        <div className="box">
          <header className="review-modal-title">
            <h4>Add review for {tourName}</h4>
            <button
              className="close-review-modal"
              aria-label="close"
              onClick={onClose}
            >
              <i className="fa-solid fa-x"></i>
            </button>
          </header>

          <form onSubmit={handleSubmit}>
            <div>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={
                    star <= (hoverRating || selectedRating)
                      ? "star selected"
                      : "star"
                  }
                  onMouseOver={() => handleMouseOver(star)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClick(star)}
                >
                  &#9733; {/* Unicode character for a star */}
                </span>
              ))}
            </div>
            <br />
            <div className="field">
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Type message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button type="submit" className="button is-primary">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddReviewModal;
