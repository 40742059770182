import React, { useEffect, useState, useContext } from "react";
import axios, { BASE_URL } from "../../api/axios";
import AuthContext from "../../context/AuthProvider"; // Import the AuthContext

const CancelledEvents = () => {
  const tours_URL = "/api/tours?format=json";
  const dmc_URL = "/api/dmc_bookings/";

  const auth = useContext(AuthContext); // Access the AuthContext
  const loggedInUserId = auth.auth.userID; // Assuming you have the guide's ID in userID
  const [dmcId, setDmcId] = useState(loggedInUserId);

  const [cancelledEvents, setCancelledEvents] = useState([]);
  const [tourDetails, setTourDetails] = useState({});

  useEffect(() => {
    axios.get(`/api/dmc_id/${loggedInUserId}/`).then((response) => {
      setDmcId(response.data.dmc_id);
    });
  }, [loggedInUserId]);

  useEffect(() => {
    axios.get(`${dmc_URL}${dmcId}/`).then((response) => {
      const cancelledEventsData = response.data.bookings.filter(
        (booking) => booking.status === "cancelled"
      );
      setCancelledEvents(cancelledEventsData);
    });
  }, [dmcId]);

  useEffect(() => {
    axios.get(tours_URL).then((response) => {
      const tours = response.data.tours;
      const tourDetailsMap = {};
      tours.forEach((tour) => {
        tourDetailsMap[tour.id] = {
          name: tour.name,
          coverImage: tour.cover_image, // Store cover image URL
        };
      });
      setTourDetails(tourDetailsMap);
    });
  }, []);

  return (
    <div>
      <div className="scrollable">
        {cancelledEvents.length > 0 ? (
          cancelledEvents.map((booking) => (
            <div className="booking-card" key={booking.id}>
              <div>
                <img
                  src={BASE_URL + tourDetails[booking.tour]?.coverImage}
                  alt={booking.tour.name}
                />
              </div>
              <div>
                <span>
                  <h3>{tourDetails[booking.tour]?.name || booking.tour}</h3>
                </span>
                <p>
                  <i className="fa-solid fa-calendar-days"></i> &nbsp;
                  {booking.date}&nbsp;&nbsp;
                  <br />
                  <i className="fa-solid fa-user-group"></i> &nbsp;
                  {booking.num_guests}&nbsp;&nbsp;
                  <br />
                  {booking.status}&nbsp;&nbsp;
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No cancelled events.</p>
        )}
      </div>
    </div>
  );
};

export default CancelledEvents;
