import React from "react";
import Navbar from "./navbar";
import "../css/admin.css";
import useRefreshToken from "../hooks/useRefreshToken";

const Admin = () => {
  const refresh = useRefreshToken();

  const handleClick = async () => {
    console.log("Admin: Test Refresh button clicked"); // Add this line
    const newToken = await refresh();
    console.log("Admin: New token", newToken); // Add this line
  };

  return (
    <div className="AdminPage">
      <Navbar />
      <div className="admin-panel">
        <div>
          <h1>Admin Dashboard</h1>
          <div className="admin-dashboard">
            <button onClick={handleClick}>Test Refresh</button>
            <div>DMCs</div>
            <div>Guides</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
