import React, { useState } from "react";
import Navbar from "../components/navbar guide";
import Footer from "../components/footer";
import ProfileSection from "../components/guide settings/profileSection";
import EmailPreferencesSection from "../components/guide settings/emailSection";
import PasswordSection from "../components/guide settings/passwordSection";
import NotificationSection from "../components/guide settings/notificationSection";
import BillingSection from "../components/guide settings/billingSection";
import AccountSection from "../components/guide settings/accountSection";

const GuideSettings = () => {
  const [activeSection, setActiveSection] = useState("Profile");
  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const settingsMenu = [
    "Profile",
    "Email preferences",
    "Password",
    "Notification",
    "Billing",
    "Account",
  ];

  return (
    <div>
      <Navbar />
      <h1 style={{ paddingLeft: "50px", fontWeight: "normal" }}>Settings</h1>
      <div className="main-window">
        <div className="side-menu">
          {settingsMenu.map((text, index) => (
            <button
              className="side-menu-button"
              onClick={() => handleButtonClick(text)}
              key={index}
            >
              {text}
            </button>
          ))}
        </div>
        <div className="details-pane">
          <div className="details-pane-heading">
            <h2>{activeSection}</h2>
          </div>
          <div className="details-pane-contents">
            {activeSection === "Profile" && <ProfileSection />}
            {activeSection === "Email preferences" && (
              <EmailPreferencesSection />
            )}
            {activeSection === "Password" && <PasswordSection />}
            {activeSection === "Notification" && <NotificationSection />}
            {activeSection === "Billing" && <BillingSection />}
            {activeSection === "Account" && <AccountSection />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GuideSettings;
