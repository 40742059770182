import React, { useState, useEffect, useContext } from "react";
import axios, { BASE_URL } from "../../api/axios";
import AuthContext from "../../context/AuthProvider";

const ProfileSection = () => {
  const auth = useContext(AuthContext);
  const loggedInUserId = auth.auth.userID;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    agency: "",
    city: "",
    bio: "",
    profile_pic: "",
  });

  useEffect(() => {
    axios.get(`${BASE_URL}api/dmcs/${loggedInUserId}`).then((response) => {
      const userData = response.data;
      setFormData({
        firstName: userData.user.first_name, // Update field names accordingly
        lastName: userData.user.last_name, // Update field names accordingly
        email: userData.user.email,
        phone: userData.phone_number,
        agency: userData.company_name,
        city: userData.location,
        bio: userData.bio,
        profile_pic: userData.profile_pic,
      });
      console.log(response.data);
    });
  }, [loggedInUserId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`${BASE_URL}api/dmcs/${loggedInUserId}`, formData)
      .then((response) => {
        // Handle the response, e.g., show a success message
      });
  };

  return (
    <div className="profile-page">
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex" }}>
          <div style={{ paddingRight: "15px" }}>
            <label>
              First Name:
              <br />
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
            </label>
            <br />
            <label>
              Last Name:
              <br />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div style={{ height: "160px", width: "160px" }}>
            <label>
              Profile picture:
              <br />
              <img
                style={{ height: "150px" }}
                src={BASE_URL + formData.profile_pic}
                alt="Profile img"
              />
            </label>
          </div>
        </div>

        <label>
          Email:
          <br />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </label>
        <br />

        <label>
          Phone number:
          <br />
          <input
            type="number"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </label>
        <br />

        <label>
          Agency:
          <br />
          <input
            type="text"
            name="agency"
            value={formData.agency}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <label>
          City:
          <br />
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
          />
        </label>
        <br />

        <label>
          Bio:
          <br />
          <textarea
            name="bio"
            value={formData.bio}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <br />

        <button className="button" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default ProfileSection;
