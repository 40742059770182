import React, { useEffect, useState, useContext } from "react";
import axios, { BASE_URL } from "../../api/axios";
import AuthContext from "../../context/AuthProvider"; // Import the AuthContext
import AddReviewModal from "./addReviewModal";

const PastEvents = () => {
  const tours_URL = "/api/tours?format=json";
  const dmc_URL = "/api/dmc_bookings/";

  const auth = useContext(AuthContext); // Access the AuthContext
  const loggedInUserId = auth.auth.userID; // Assuming you have the guide's ID in userID
  const [dmcId, setDmcId] = useState(loggedInUserId);

  const [pastEvents, setPastEvents] = useState([]);
  const [tourDetails, setTourDetails] = useState({});

  useEffect(() => {
    axios.get(`/api/dmc_id/${loggedInUserId}/`).then((response) => {
      setDmcId(response.data.dmc_id);
    });
  }, [loggedInUserId]);

  useEffect(() => {
    axios.get(`${dmc_URL}${dmcId}/`).then((response) => {
      const today = new Date();
      const pastEventsData = response.data.bookings.filter(
        (booking) => new Date(booking.date) < today
      );
      setPastEvents(pastEventsData);
    });
  }, [dmcId]);

  useEffect(() => {
    axios.get(tours_URL).then((response) => {
      const tours = response.data.tours;
      const tourDetailsMap = {};
      tours.forEach((tour) => {
        tourDetailsMap[tour.id] = {
          name: tour.name,
          coverImage: tour.cover_image, // Store cover image URL
        };
      });
      setTourDetails(tourDetailsMap);
    });
  }, []);

  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleAddReview = (booking) => {
    setSelectedBooking(booking);
  };

  const handleCloseModal = () => {
    setSelectedBooking(null);
  };

  return (
    <div>
      <div className="scrollable">
        {pastEvents.length > 0 ? (
          pastEvents.map((booking) => (
            <div className="booking-card" key={booking.id}>
              <div>
                <img
                  src={BASE_URL + tourDetails[booking.tour]?.coverImage}
                  alt={booking.tour.name}
                />
              </div>
              <div>
                <span>
                  <h3>{tourDetails[booking.tour]?.name || booking.tour}</h3>
                </span>
                <p>
                  <i className="fa-solid fa-calendar-days"></i> &nbsp;
                  {booking.date}&nbsp;&nbsp;
                  <br />
                  <i className="fa-solid fa-user-group"></i> &nbsp;
                  {booking.num_guests}&nbsp;&nbsp;
                  <br />
                  <br />
                  <button
                    className="button"
                    onClick={() => handleAddReview(booking)}
                  >
                    Add review <i className="fa-solid fa-star"></i>
                  </button>
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No upcoming events.</p>
        )}
      </div>
      {selectedBooking && (
        <AddReviewModal
          isOpen={true}
          onClose={handleCloseModal}
          tourName={
            tourDetails[selectedBooking.tour]?.name || selectedBooking.tour
          }
          bookingId={selectedBooking.id}
        />
      )}
    </div>
  );
};

export default PastEvents;
