import React, { useState } from "react";
import Navbar from "../components/navbar guide";
import GuideConversations from "../components/guide messages/guide convesations";
import GuideMessageSettings from "../components/guide messages/guideMessagesSettings";

const GuideMessages = () => {
  const [activeSection, setActiveSection] = useState("Conversations");
  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const messagesMenu = ["Conversations", "Message preferences"];

  return (
    <div>
      <Navbar />
      <h1 style={{ paddingLeft: "40px" }}>Messages</h1>
      <div className="main-window">
        <div className="side-menu">
          {messagesMenu.map((text, index) => (
            <button
              className="side-menu-button"
              onClick={() => handleButtonClick(text)}
              key={index}
            >
              {text}
            </button>
          ))}
        </div>
        <div className="details-pane">
          <div className="details-pane-heading">
            <h2>{activeSection}</h2>
          </div>
          <div className="details-pane-contents">
            {activeSection === "Conversations" && <GuideConversations />}
            {activeSection === "Message preferences" && (
              <GuideMessageSettings />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideMessages;
