import React from "react";

const emailSection = () => {
  return (
    <div>
      <p>user@email.address</p>
      <p>Recieve email notifications</p>
      <div className="container">
        Yes &nbsp;
        <label class="switch">
          <input type="checkbox" />
          <span class="slider round"></span>
        </label>
        &nbsp;No
      </div>
    </div>
  );
};

export default emailSection;
