import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/footer';
import logo from  '../images/Asset 1.svg';

const AboutUs = () => {
    const navigate = useNavigate();
    const navToLogin = () => {
      navigate('/login');
    }

    return (
        <div className="AboutUsPage">
            <header className="home-nav">
                <Link to="/"><img src={logo} className="home-logo" alt="logo" /></Link>

                <div className="home-nav-btn-div">
                    <button className="home-nav-btn" onClick={navToLogin}>Login</button>
                </div>
            </header>
            <div className="about-us-body">
                <h1>About Us</h1>
                <h4>What is Honest Travel Guides</h4>
                <div>
                    <p>Honest Guide is a plaform that was created from the vantage point of The platform was created to meet the industry needs of both bookers and guides through the real world experience gained from The Honest Group chain of travel agencies. Bringing real world knowledge to provide tangible solutions to the travel industry. Honest Guides is a much needed product in the travel industry at the moment, as the platform regulates the relationship between agent and guides.</p>
                    <p>The platform allows agents to pick which guides they want to work with based on their reviews and specialties in the different parts of the world. Payments are handled through the platform where agents get to book and cancel guides.</p>
                    <p>Guides get to choose which agencies they work with and how much they get to charge. The platform will have a minimum amount for all guides and agencies can make offers to guides based on their requirements for that specific tour.</p>
                    <p>The platform protects guides from under-paid whilst simultaneously holding them to a higher international standard ensuring bookers/agents get the best possible service and value for money as well as satisfied clientele and memorable experiences.</p>
                    <p>COVID-19 showed us a different side of the tourism industry that we never expected to see. Overnight all the freelance guides were unable to work due to lockdowns and restrictions while receiving zero aid from the very same agencies they once worked for. This really impacted the livelihoods, businesses and families that are all linked to freelance work in the travel industry. </p>
                    <p>Through this platform we aim to ensure this never happens again by registering guides onto our platform and making them a part of a global community committed to the well being of all the people involved in making memorable travel possible. Some of the benefits we offer our guides include:</p>
                    <p>Minimum Wage
                        Medical Insurance
                        Pay
                        UIF<br/>
                        To name a few of the benefits of why guides should register with us.
                    </p>
                    <p>The platform will also allow guides to blacklist agents and clients who willfully refuse to follow our established community guidelines. This comes in response to the instances where a client review seriously impacts the day-to-day operations of a guide’s business without providing them a chance to provide context or simply reject claims that may not be true. </p>
                    <p>The platform creates a global community that ensures when travelers go to new places they are held to a standard which requires them to be respectful and considerate of the local customs creating a healthy and positive travel community. Protecting travelers and providing a memorable travel experience while also protecting the locals and indigenous people of the places that open up their lands and homes to welcome travelers on their quest to see the world.</p>
                    <p>Agents and tour operators can pick guides based on their reviews and product offering, ensuring their clients will be in good hands and if anything goes wrong the guides will be held accountable as the reviews will be on the guides profile and be a reflection of the quality of service they provide.</p>
                    <p>The platform will raise the current standards and bring peace of mind to the entire service supply chain of the travel industry.</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
