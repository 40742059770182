import React, { useEffect, useState, useContext } from "react";
import axios, { BASE_URL } from "../../api/axios";
import AuthContext from "../../context/AuthProvider"; // Import the AuthContext
import SendMessageModal from "../dmc messages/sendMessageModal";

const UpcomingEvents = () => {
  const tours_URL = "/api/tours?format=json";
  const dmc_URL = "/api/dmc_bookings/";

  const auth = useContext(AuthContext); // Access the AuthContext
  const loggedInUserId = auth.auth.userID; // Assuming you have the guide's ID in userID
  const [dmcId, setDmcId] = useState(loggedInUserId);

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [tourDetails, setTourDetails] = useState({});

  const [bookingStatus, setBookingStatus] = useState({});

  useEffect(() => {
    axios.get(`/api/dmc_id/${loggedInUserId}/`).then((response) => {
      setDmcId(response.data.dmc_id);
    });
  }, [loggedInUserId]);

  useEffect(() => {
    axios.get(`${dmc_URL}${dmcId}/`).then((response) => {
      const today = new Date();
      const upcomingEventsData = response.data.bookings.filter(
        (booking) =>
          new Date(booking.date) >= today && booking.status !== "cancelled"
      );
      setUpcomingEvents(upcomingEventsData);
    });
  }, [dmcId]);

  useEffect(() => {
    axios.get(tours_URL).then((response) => {
      const tours = response.data.tours;
      const tourDetailsMap = {};
      tours.forEach((tour) => {
        tourDetailsMap[tour.id] = {
          name: tour.name,
          coverImage: tour.cover_image, // Store cover image URL
        };
      });
      setTourDetails(tourDetailsMap);
    });
  }, []);

  const handleStatusChange = (bookingId, newStatus) => {
    // Update the local state
    setBookingStatus({
      ...bookingStatus,
      [bookingId]: newStatus,
    });

    // Send a PUT request to update the booking status on the server
    axios
      .put(`${BASE_URL}api/bookings/${bookingId}`, { status: newStatus })
      .then((response) => {
        // Handle the response, e.g., show a success message
        //console.log(response);
      })
      .catch((error) => {
        // Handle errors if needed
        //console.log(error);
      });
  };

  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleSendMessage = (booking) => {
    setSelectedBooking(booking);
  };

  const handleCloseModal = () => {
    setSelectedBooking(null);
  };

  return (
    <div>
      <div className="scrollable">
        {upcomingEvents.length > 0 ? (
          upcomingEvents.map((booking) => (
            <div className="booking-card" key={booking.id}>
              <div>
                <img
                  src={BASE_URL + tourDetails[booking.tour]?.coverImage}
                  alt={booking.tour.name}
                />
              </div>
              <div>
                <span>
                  <h3>{tourDetails[booking.tour]?.name || booking.tour}</h3>
                </span>
                <p>
                  <i className="fa-solid fa-calendar-days"></i> &nbsp;
                  {booking.date}&nbsp;&nbsp;
                  <br />
                  <i className="fa-solid fa-user-group"></i> &nbsp;
                  {booking.num_guests}&nbsp;&nbsp;
                  <br />
                  {booking.status}&nbsp;&nbsp;
                  <br />
                  <i className="fa-solid fa-hourglass-start"></i>&nbsp;&nbsp;
                  <select
                    name="tour-status"
                    id="tour-status"
                    value={bookingStatus[booking.id] || "pending"}
                    onChange={(e) =>
                      handleStatusChange(booking.id, e.target.value)
                    }
                  >
                    <option value="pending">Pending</option>
                    <option value="completed">Done</option>
                    <option value="cancelled">Cancel</option>
                  </select>
                  <br />
                  <br />
                  <button
                    className="button"
                    onClick={() => handleSendMessage(booking)}
                  >
                    Send message <i className="fa-solid fa-paper-plane"></i>
                  </button>
                </p>
              </div>
              {selectedBooking && (
                <SendMessageModal
                  isOpen={true}
                  onClose={handleCloseModal}
                  tourName={
                    tourDetails[selectedBooking.tour]?.name ||
                    selectedBooking.tour
                  }
                  bookingId={selectedBooking.id}
                />
              )}
            </div>
          ))
        ) : (
          <p>No upcoming events.</p>
        )}
      </div>
    </div>
  );
};

export default UpcomingEvents;
