import React from "react";

const billingSection = () => {
  return (
    <div className="billing-page">
      <p>
        Name or Company name
        <br />
        <input type="text" />
      </p>
      <p>
        Address
        <br />
        <input type="" />
      </p>
      <p>
        City
        <br />
        <input type="text" />
      </p>
      <p>
        Postal code
        <br />
        <input type="number" />
      </p>
      <p>
        Country
        <br />
        <input type="text" />
      </p>
      <p>
        VAT number
        <br />
        <input type="number" />
      </p>
      <input type="submit" className="button" value="Send" />
    </div>
  );
};

export default billingSection;
