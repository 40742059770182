import Navbar from '../components/navbar';
 
const Logout = () => {
  return (
    <div className="Logout">
      <Navbar />  

      <h1>Logged Out</h1>

    </div>
  );
}

export default Logout;
