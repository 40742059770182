import React from "react";

const notificationSection = () => {
  return (
    <div>
      <p>Configure notifications</p>
      <p>Recieve email notification when...</p>
      <p>
        <div className="container">
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round"></span>
          </label>
          &nbsp; When someone sends me a message
        </div>
      </p>
      <p>
        <div className="container">
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round"></span>
          </label>
          &nbsp; When someone post a review about my tour
        </div>
      </p>
      <p>
        <div className="container">
          <label class="switch">
            <input type="checkbox" />
            <span class="slider round"></span>
          </label>
          &nbsp; I agree to receive travel tips, tricks and promotions from
          Honest Travel Guides
        </div>
      </p>
    </div>
  );
};

export default notificationSection;
