import React, { useState, useContext, useEffect } from "react";
import Navbar from "../components/navbar dmc";
import Select from "react-select";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../context/AuthProvider";
import axios from "../api/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BookTour = () => {
  const auth = useContext(AuthContext);
  const loggedInUserId = auth.auth.userID;
  const [dmcId, setDmcId] = useState();

  useEffect(() => {
    axios.get(`/api/dmc_id/${loggedInUserId}/`).then((response) => {
      setDmcId(response.data.dmc_id);
    });
  }, [loggedInUserId]);

  // Access the passed data from the location.state object
  const location = useLocation();
  const { tourDetails } = location.state ?? { tourDetails: null };
  //console.log(tourDetails);

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [showKidsDropdown, setShowKidsDropdown] = useState(false);
  const [kidsOption, setKidsOption] = useState("no");
  const [totalCost, setTotalCost] = useState();

  const options = Array.from({ length: 50 }, (_, i) => ({
    value: i + 1,
    label: (i + 1).toString(),
  }));

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setKidsOption(value);
    setShowKidsDropdown(value === "yes");
  };

  // State variables for adults and kids count
  const [adultsCount, setAdultsCount] = useState(1);
  const [kidsCount, setKidsCount] = useState(0);

  // Function to update the total cost based on the selected options
  const updateTotalCost = (adultsCount, kidsCount) => {
    const adultsPrice = tourDetails.price;
    const kidsPrice = adultsPrice * 0.5;
    const totalCost = adultsPrice * adultsCount + kidsPrice * kidsCount;
    return totalCost;
  };

  const num_guests = adultsCount + kidsCount;

  // Event handler for changing adults count
  const handleAdultsCountChange = (selectedOption) => {
    setAdultsCount(selectedOption.value);
    const updatedTotalCost = updateTotalCost(selectedOption.value, kidsCount);
    setTotalCost(updatedTotalCost);
  };

  // Event handler for changing kids count
  const handleKidsCountChange = (selectedOption) => {
    setKidsCount(selectedOption.value);
    const updatedTotalCost = updateTotalCost(adultsCount, selectedOption.value);
    setTotalCost(updatedTotalCost);
  };

  const handleContinue = () => {
    // Prepare the data to be sent to the backend
    const bookingData = {
      tour: tourDetails?.id,
      date: startDate.toISOString().split("T")[0],
      num_guests: num_guests,
      booking_type: "tour",
      booking_status: "pending",
      booking_agent: dmcId,
      booked_guide: tourDetails?.guide?.id,
      booking_fee: totalCost,
    };

    axios
      .post("api/bookings", bookingData)
      .then((response) => {
        // Handle the response from the backend if needed
        //console.log(response.data);

        // Redirect to the booking page on success
        navigate("/bookings"); // Replace '/booking' with the actual URL of your booking page
      })
      .catch((error) => {
        // Handle errors if necessary
        console.error("Error creating booking:", error);
      });
  };

  return (
    <div>
      <Navbar />
      <form className="book-tour">
        <h1>Booking Details</h1>
        <br />
        {tourDetails && <b>Tour Name: {tourDetails.name}</b>}
        <br />
        <br />
        <b>R {totalCost}</b>
        <br />
        <br />
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          dateFormat="yyyy-MM-dd"
        />
        <br />
        <br />
        <span>
          <b>Kids (Under 14 years old)</b>
        </span>
        <br />
        <label htmlFor="no">No</label>
        <input
          type="radio"
          id="no"
          name="kids"
          value="no"
          checked={kidsOption === "no"}
          onChange={handleRadioChange}
        />
        <label htmlFor="yes">Yes</label>
        <input
          type="radio"
          id="yes"
          name="kids"
          value="yes"
          checked={kidsOption === "yes"}
          onChange={handleRadioChange}
        />
        <br />
        <br />
        <span>
          <b>Adults:</b>
        </span>
        <Select
          className="select"
          defaultValue={1}
          options={options}
          onChange={handleAdultsCountChange}
          required
        />
        <br />
        {showKidsDropdown && (
          <div>
            <span>
              <b>Kids (Under 14 years old):</b>
            </span>

            <Select
              className="select"
              options={options}
              onChange={handleKidsCountChange}
            />
          </div>
        )}
        <br />
        <br />
        <Link className="button" onClick={handleContinue}>
          Continue
        </Link>
      </form>
    </div>
  );
};

export default BookTour;
